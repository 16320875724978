<template>
    <div v-loading="loadingData">
        <el-form ref="form" :model="form" class="aticler_m p-0" size="small">
            <el-form-item label="Состояние">
                <el-input 
                 class="mt-2"
                :rows="2"
                :placeholder="$t('message.comment')"
                v-model="form.comment" 
                ></el-input>
            </el-form-item>
        </el-form>
        <el-row class="row_but">
             <el-row class="row_but">
             <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save') }}</el-button>
            <el-button @click="close()">{{ $t('message.close') }}</el-button>
        </el-row>
        </el-row>
    </div>
</template>

<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';

    
    import drawerChild from '@/utils/mixins/drawer-child';
    export default {
       mixins:[form, drawerChild],
        props:['selected'],
        components:{},
        data(){
            return {
                loadingData: false,
                dialogTableVisible2: false,
            }
        },
         watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        created(){},
        computed: {
            ...mapGetters({
                rules: 'patientIndicatorIndeces/rules',
                columns: 'patientIndicatorIndeces/columns',
                model: 'patientIndicatorIndeces/model',
            })
        },
        methods:{
            ...mapActions({
                save: 'patientIndicatorIndeces/update',
                editModel: 'patientIndicatorIndeces/show',
                empty: 'patientIndicatorIndeces/empty',
                updateList: 'patientIndicatorIndeces/index',
            }),
            afterOpened(){
                if (this.selected && !this.loadingData) {
                    this.loadingData = true;
                    this.editModel(this.selected.id)
                        .then(async (res) => {
                            this.loadingData = false;
                            this.form = JSON.parse( JSON.stringify(this.model) );
                        })
                        .catch(err => {
                            this.loadingData = false;
                            this.$alert(err)
                        });                    
                }
            },
            fetchData() {
                const query = { ...this.filter };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            close(){
                this.$emit('c-close');
            },
            afterClosed(){
              this.empty()
            },
            loadModel(){
              this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submit(close = true){
                this.form.patient_indicator_id = this.model.patientIndicator.id;
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged()
                                 if (close == true) {
                                    this.close();
                                }
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
        }
         
    }
</script>